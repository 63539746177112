@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
	font-family: 'Poppins', sans-serif;
	color: #2f3543;
}

.main-bg {
	background: url('assets/background.webp') no-repeat center/cover;
	height: 100vh;
}

.couveture-bg {
	background: url('assets/background.webp') no-repeat center/cover;
	height: 50vh;
}

.charpente-bg {
	background: url('assets/services/charpente2.webp') no-repeat center/cover;
	height: 50vh;
}

.zinguerie-bg {
	background: url('assets/services/zinguerie2.webp') no-repeat center/cover;
	height: 50vh;
}

.surelevation-bg {
	background: url('assets/services/surelevation2.webp') no-repeat center/cover;
	height: 50vh;
}

.isolation-bg {
	background: url('assets/services/isolation3.webp') no-repeat center/cover;
	height: 50vh;
}

.underline-expand {
	position: relative;
	text-decoration: none;

	&:before,
	&:after {
		content: '';
		position: absolute;
		height: 2px;
		width: 0;
		bottom: -2px;
		background-color: #2f3543;
		transition: width 0.2s ease-in-out;
	}

	&:before {
		left: 50%;
		transform: translateX(-50%);
	}

	&:after {
		right: 50%;
		transform: translateX(50%);
	}

	&:hover:before {
		width: 100%;
	}

	&:hover:after {
		width: 100%;
	}
}

.underline-expand-white {
	position: relative;
	text-decoration: none;

	&:before,
	&:after {
		content: '';
		position: absolute;
		height: 2px;
		width: 0;
		bottom: -2px;
		background-color: #fff;
		transition: width 0.2s ease-in-out;
	}

	&:before {
		left: 50%;
		transform: translateX(-50%);
	}

	&:after {
		right: 50%;
		transform: translateX(50%);
	}

	&:hover:before {
		width: 100%;
	}

	&:hover:after {
		width: 100%;
	}
}

.dots {
	background: radial-gradient(#d7d7d7 3px, transparent 4px) 0 0 / 30px 30px;
}

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

::-webkit-scrollbar-track {
	border-radius: 10px;
	background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:hover {
	background: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-thumb:active {
	background: rgba(0, 0, 0, 0.9);
}

a {
	transition: color 0.3s;
	-webkit-transition: color 0.3s;
}
